<template>
	<div class="kpi-page user-page">
		<div class="content-wrapper">
			<h2>{{ campaignName }}</h2>
		</div>
		<b-card>
			<b-card-title>
				<span>Kpis List</span>
				<view-lang-select
					v-model="view_lang"
					:options="languages"
					class="float-right"
				/>
			</b-card-title>
			<b-row>
				<b-col md="9" xl="6" class="pt-4">
					<draggable
						:list="tree"
						handle=".kpi-handle"
						@change="postNewPosition"
						ghost-class="ghost-item"
					>
						<div v-for="item in tree" :key="item.id">
							<div class="nestable-whappy-item d-flex align-items-center item">
								<!-- GROUP -->
								<!-- <span class="ml-2 text-muted kpis-label" style="width: 2rem">{{
                  item.group
                }}</span> -->
								<!-- <span
                  class="ml-2 text-muted kpis-label"
                  style="width: 2.5rem"
                  >{{ item.type == 0 ? "ABS" : "INC" }}</span
                > -->

								<span
									v-if="item.viewMode == 'supervisor'"
									class="ml-2 text-muted kpis-label"
									style="width: 2rem"
								>
									<b-icon-diagram-3-fill></b-icon-diagram-3-fill>
								</span>

								<span v-else>
									<span class="ml-2 text-muted kpis-label" style="width: 2rem">
										<b-icon-person-fill
											v-if="
												item.viewMode_showme == true ||
													item.viewMode == 'singola'
											"
										></b-icon-person-fill>
										<b-icon-person v-else></b-icon-person>
									</span>

									<span class="ml-2 text-muted kpis-label" style="width: 2rem">
										<b-icon-people
											v-if="item.viewMode == 'singola'"
										></b-icon-people>
										<b-icon-people-fill v-else></b-icon-people-fill>
									</span>
								</span>

								<div class="py-2 flex-grow">
									<div style="height: 15px">
										<span class="ml-2"
											><strong>{{
												item.friendly_name[view_lang]
											}}</strong></span
										>
									</div>

									<div class="mt-1">
										<span
											style="font-size: 0.7rem; font-style: italic"
											class="ml-2"
										>
											{{ item.name }}</span
										>
									</div>
								</div>
								<span class="ml-auto mr-2 kpis-label">{{
									item.numero_azioni
								}}</span>
								<div
									class="tools-box align-self-stretch d-flex align-items-center"
								>
									<b-icon
										icon="arrows-move"
										class="pointer kpi-handle"
									></b-icon>
									<b-icon
										icon="plus"
										class="pointer"
										v-b-toggle.sidebar-kpis
										@click="tmpKpi.id_padre = item.id"
									>
									</b-icon>
									<b-icon
										icon="gear-fill"
										class="pointer"
										v-b-toggle.sidebar-kpis
										@click="setTmpKpi(item)"
									></b-icon>
									<b-icon
										icon="trash-fill"
										class="pointer"
										@click="deleteCheck(item)"
									></b-icon>
								</div>
							</div>
							<div
								class="
                  nestable-whappy-item
                  d-flex
                  align-items-center
                  mb-2
                  subItem
                "
								v-for="subItem in item.children"
								:key="subItem.id"
							>
								<span class="ml-2"
									><strong>{{ subItem.friendly_name[view_lang] }}</strong></span
								>
								<span class="ml-auto mr-2 kpis-label">{{
									subItem.numero_azioni
								}}</span>
								<div
									class="tools-box align-self-stretch d-flex align-items-center"
								>
									<b-icon
										icon="arrows-move"
										style="visibility: hidden"
									></b-icon>
									<b-icon icon="plus" style="visibility: hidden"></b-icon>
									<b-icon
										icon="gear-fill"
										class="pointer"
										v-b-toggle.sidebar-kpis
										@click="setTmpKpi(subItem)"
									></b-icon>
									<b-icon
										icon="trash-fill"
										class="pointer"
										@click="deleteCheck(subItem)"
									></b-icon>
								</div>
							</div>
						</div>
					</draggable>
				</b-col>
			</b-row>

			<b-row class="mt-5">
				<b-col>
					<b-button
						squared
						size="lg"
						class="button-dashed big-button"
						@click="setTmpKpi"
						v-b-toggle.sidebar-kpis
						>Add new Kpi</b-button
					>
				</b-col>
			</b-row>
		</b-card>
		<br />
		<b-card class="mb-4">
			<b-card-title> Update from spreadsheet (.xlsx) </b-card-title>

			<b-row class="my-1 align-items-end">
				<b-col lg="5">
					<label style="color: #c25052" v-if="!idCampaign"
						>First select the company and then upload the model</label
					>
					<label v-if="idCampaign">Select a file to upload (.xlsx)</label>
					<b-form-file
						:disabled="!idCampaign"
						v-model="file"
						placeholder="Choose a file or drop it here..."
						drop-placeholder="Drop file here..."
						accept=".xlsx, .XLSX"
					></b-form-file>
					<b-icon
						v-if="loadingFile"
						class="loading-file"
						icon="arrow-clockwise"
						animation="spin"
						font-scale="2"
					></b-icon>
				</b-col>
				<b-col lg="7">
					<b-button v-if="isLoading" variant="primary" disabled>
						<b-spinner small type="grow"></b-spinner>
						Loading...
					</b-button>
					<b-button
						v-else
						variant="primary"
						:disabled="file == null"
						@click="uploadItem"
						>Upload</b-button
					>
					<b-button
						class="ml-2 float-right"
						variant="outline-primary"
						download
						:disabled="!idCampaign"
						@click="downloadKpiModel"
					>
						<b-icon icon="file-earmark-spreadsheet" class="mr-2"></b-icon>
						Download model
					</b-button>
				</b-col>
			</b-row>
			<b-row class="justify-content-end">
				<b-col cols="auto">
					<p
						v-if="idCampaign == null"
						style="font-size: 0.7rem; color: #c25052"
					>
						Select the company to download the model
					</p>
				</b-col>
			</b-row>
		</b-card>

		<div class="content-wrapper">
			<b-button squared variant="primary" size="lg" @click="saveItem"
				><template v-if="hasNextRoute()">Save and continue</template
				><template v-else>Save</template></b-button
			>
		</div>
		<b-button
			v-if="isDebug"
			class="fixed-button py-2"
			v-b-toggle.sidebar-iframe
		>
			<img src="../../assets/images/phone.png" class="img-fluid" />
		</b-button>
		<b-sidebar
			@hidden="resetDataSidebarIframe"
			class="right-sidebar iframe-sidebar"
			id="sidebar-iframe"
			width="500px"
			right
			shadow
			lazy
		>
			<div class="login-as">
				<h4>Login as:</h4>
				<b-select
					@change="getUrlDemo"
					:options="usersList"
					v-model="userSelected"
				>
				</b-select>
				<!-- user selectd: {{ userSelected }} || url demo: {{ url_demo }} -->
			</div>
			<div class="iframe-phone">
				<iframe
					class="iframe-phone-iframe"
					:src="url_demo"
					frameborder="0"
				></iframe>
			</div>
		</b-sidebar>
		<b-sidebar
			class="right-sidebar"
			id="sidebar-kpis"
			right
			shadow
			lazy
			@hidden="resetData"
		>
			<div class="content-wrapper my-3 mx-1">
				<h2 v-if="tmpKpi.id">Edit KPI</h2>
				<h2 v-else-if="tmpKpi.id_padre && !tmpKpi.id">Add subset KPI</h2>
				<h2 v-else>Add new KPI</h2>

				<b-form-group label="Global KPI name" class="mt-4">
					<b-form-select
						v-model="tmpKpi.id_globalKpi"
						:options="globalKpiOptions"
					></b-form-select>
				</b-form-group>
				<b-form-group label="Friendly name">
					<b-form-input v-model="tmpKpi.friendly_name[friendlyName_lang]" />
					<LangOptions v-model="friendlyName_lang" :options="languages" />
				</b-form-group>

				<template v-if="!tmpKpi.id_padre">
					<!-- <b-form-group label="Group" class="mt-4">
            <b-form-input v-model="tmpKpi.group" />
          </b-form-group> -->
					<!-- <b-form-group label="Type" class="mt-4">
            <b-form-select
              v-model="tmpKpi.type"
              :options="type_options"
            ></b-form-select>

            <b-row v-if="tmpKpi.type == 2">
              <b-col class="col-6">
                <b-form-select
                  v-model="pluginSelected"
                  :options="pluginsOptions"
                  class="mt-2 pl-4"
                ></b-form-select>
              </b-col>
              <b-col class="col-6">
                <b-form-select
                  v-if="pluginSelected == 1"
                  v-model="productSelected"
                  :options="productsOptions"
                  class="mt-2 pl-4"
                ></b-form-select>
              </b-col>
            </b-row>
          </b-form-group> -->
					<b-form-group label="View setting" class="mt-4 mb-1">
						<b-form-select
							v-model="tmpKpi.viewMode"
							:options="view_options"
						></b-form-select>
					</b-form-group>
					<b-form-group
						v-if="
							tmpKpi.viewMode == 'group_team' ||
								tmpKpi.viewMode == 'group_peers' ||
								tmpKpi.viewMode == 'group_area' ||
								tmpKpi.viewMode == 'group_cluster' ||
								tmpKpi.viewMode == 'group_department'
						"
					>
						<b-form-checkbox v-model="tmpKpi.viewMode_showme"
							>Show me in group</b-form-checkbox
						>
					</b-form-group>
					<b-form-group class="mt-2">
						<b-form-checkbox
							v-if="tmpKpi.viewMode != 'singola'"
							v-model="tmpKpi.expandable_view"
							>Expandable view
						</b-form-checkbox>
					</b-form-group>
					<b-form-group
						v-if="
							tmpKpi.viewMode == 'group_team' ||
								tmpKpi.viewMode == 'group_peers' ||
								tmpKpi.viewMode == 'group_area' ||
								tmpKpi.viewMode == 'group_cluster' ||
								tmpKpi.viewMode == 'group_department' ||
								tmpKpi.viewMode == 'supervisor'
						"
						label="Goal type"
						class="mt-2"
					>
						<b-form-select
							v-model="tmpKpi.goal_type"
							:options="goal_type_options"
						>
						</b-form-select>
					</b-form-group>
					<b-form-group label="View time" class="mt-4">
						<b-form-select
							v-model="tmpKpi.viewTime"
							:options="view_time_options"
						></b-form-select>
					</b-form-group>
					<b-form-group label="Ranking view">
						<b-form-select
							v-model="tmpKpi.rankingView"
							:options="rankingView_options"
						></b-form-select>
					</b-form-group>
				</template>
				<!-- <b-form-group label="Default goal" class="mt-4">
          <b-form-input v-model="tmpKpi.default_goal" />
        </b-form-group> -->
				<!-- <b-form-group
          label="Action divider (Kpi value to get 1 Action)"
          class="mt-4"
        >
          <b-form-input v-model="tmpKpi.divisore_azione" />
        </b-form-group> -->
				<b-form-group label="Number of actions" class="mt-4">
					<b-form-input number type="number" v-model="tmpKpi.numero_azioni" />
				</b-form-group>
				<b-form-group label="Ranking points for each Action" class="mt-4">
					<b-form-input number type="number" v-model="tmpKpi.ranking_points" />
				</b-form-group>

				<div v-if="tmpKpi.viewMode == 'supervisor'">
					<b-form-group label="Role">
						<TagsInput
							v-if="roleList.length"
							v-model="tmpKpi.Roles"
							:tagsList="roleList"
						/>
						<span v-else class="muted">This company has no roles</span>
					</b-form-group>

					<b-form-group label="Area">
						<TagsInput
							v-if="areaList.length"
							v-model="tmpKpi.Areas"
							:tagsList="areaList"
						/>
						<span v-else class="muted">This company has no areas</span>
					</b-form-group>

					<b-form-group label="Deparment">
						<TagsInput
							v-if="departmentList.length"
							v-model="tmpKpi.Departments"
							:tagsList="departmentList"
						/>

						<span v-else class="muted">This company has no department</span>
					</b-form-group>

					<b-form-group label="Cluster">
						<TagsInput
							v-if="clusterList.length"
							v-model="tmpKpi.Clusters"
							:tagsList="clusterList"
						/>

						<span v-else class="muted">This company has no cluster</span>
					</b-form-group>
				</div>

				<div class="mt-5 mb-5">
					<b-button
						squared
						variant="primary"
						size="lg"
						class="big-button"
						@click="saveKpi"
						>Confirm</b-button
					>
				</div>
			</div>
		</b-sidebar>
		<b-modal v-model="showAlertModal" title="Attention!" ok-only
			>Cannot delete this item, remove all nested element before
			proceeding.</b-modal
		>
	</div>
</template>

<script>
	import AxiosService from "@/axiosServices/AxiosService";
	import draggable from "vuedraggable";
	import * as CampaignRoutes from "@/utils/CampaignRoutes.js";
	export default {
		name: "KpiInCampaign",
		components: {
			draggable,
		},
		data() {
			return {
				campaignName: "",

				isDebug: null,
				idCampaign: this.$route.params.campaignId,

				goal_type_options: [
					{ value: null, text: "Seleziona il tipo di obiettivo" },
					{ value: "individual", text: "Obiettivo individuale" },
					{ value: "group", text: "Obiettivo di gruppo" },
				],

				kpi_frequency_options: [
					{ value: 0, text: "Every day" },
					{ value: 1, text: "Every week" },
					{ value: 2, text: "Every month" },
					{ value: 3, text: "Never" },
				],
				mail_options: [],
				type_options: [
					{ value: 0, text: "Absolute" },
					{ value: 1, text: "Incremental" },
					{ value: 2, text: "Plugin" },
				],
				pluginsOptions: [
					{ value: null, text: "Seleziona il Plugin" },
					{ value: 1, text: "Sales chat" },
				],
				productsOptions: [{ value: null, text: "Seleziona il Prodotto" }],
				view_options: [
					{ value: null, text: "Seleziona il tipo di vista" },
					{ value: "singola", text: "Single (Only my results)" },
					{ value: "group_team", text: "Group (My team results)" },
					{ value: "group_peers", text: "Group (My peers results)" },
					{ value: "group_area", text: "Group (My area results)" },
					{ value: "group_cluster", text: "Group (My cluster results)" },
					{ value: "group_department", text: "Group (My department results)" },
					{ value: "supervisor", text: "Supervisor (Custom target)" },
				],
				view_options_showme: false,
				view_time_options: [
					{ value: "singola", text: "Entire Campaign" },
					{ value: "month", text: "Split by Month" },
					{ value: "week", text: "Split by Week" },
					{ value: "day", text: "Split by Day" },
				],
				rankingView_options: [
					{ value: null, text: "Select ranking view" },
					{ value: "perc", text: "Percentage" },
					{ value: "num", text: "Numeric" },
				],
				globalKpiOptions: [{ value: null, text: "Seleziona il KPI Globale" }],

				roleList: [],
				areaList: [],
				departmentList: [],
				clusterList: [],

				mail_options_selected: 0,
				alternative_mail_selected: "",
				alternative_mail_options: [],

				pluginSelected: 0,
				productSelected: 0,

				items: [],
				tree: [],
				currentKpi: null,
				tmpKpi: {},
				campaign: null,
				friendlyName_lang: null,
				view_lang: null,
				showAlertModal: false,

				isNewCampaign: this.$route.params.campaignId == null,

				url_demo: "",
				usersList: [{ value: null, text: "Seleziona Utente" }],
				userSelected: null,

				file: null,
				isLoading: false,
				loadingFile: false,
			};
		},
		service: null,
		campaignService: null,
		created() {
			this.service = new AxiosService("KpiFromGlobal");
			this.campaignService = new AxiosService("Campaign");
			this.resetData();
			this.view_lang = this.default_lang.value;

			// this.service.readCustomEndpoint("Setting").then((res) => {
			//   if (res) {
			//     this.url_demo = res.url_demo;
			//   } else {
			//     console.log("ERROr calling Setting");
			//   }
			// });
			this.isDebugFunction();
		},
		mounted() {
			if (!this.isNewCampaign) {
				this.getUsersByCampaign();
			}

			let params = [{ param: "idCampagna", value: this.idCampaign }];
			this.service.read(params).then((res) => {
				res.forEach((element) => {
					this.items.push(element);
				});
				this.tree = this.buildTree(this.items);
			});
			this.campaignService.readById(this.idCampaign).then((res) => {
				this.campaign = res;
				this.campaignName = res.title[this.view_lang];

				this.getRolesByCompany();
				this.getAreasByCompany();
				this.getDepartmentsByCompany();
				this.getClustersByCompany();
			});

			this.service.readCustomEndpoint("SalesChat/GetProducts").then((res) => {
				res.forEach((element) => {
					element.value = element.id;
					element.text = element.nome;
					this.productsOptions.push(element);
				});
			});
		},
		methods: {
			async uploadItem() {
				let fileUrl = "";
				this.isLoading = true;
				this.loadingFile = true;
				if (this.file) {
					if (this.file.size > 3 * 1024 * 1024) {
						alert("I file non possono superare il massimo di 3MB");
						return;
					}
					const formData = new FormData();
					formData.append("file", this.file);
					let fileName = await this.service
						.uploadFile(formData)
						.then((res) => res.data)
						.finally(() => {
							this.loadingFile = false;
						});
					if (fileName) {
						fileUrl = "/Drive/" + fileName;
					}
				}

				if (fileUrl) {
					const fileUrlObject = { filePath: fileUrl };
					this.service
						.postCustomEndpoint(
							"CampaignManager/UpdateEstimateByFile",
							this.idCampaign,
							fileUrlObject
						)
						.then(() => {
							this.file = null;
							this.$successToast();
						})
						.catch(() => {
							this.$errorToast();
							return false;
						});
				} else {
					this.$errorToast();
					return false;
				}
				this.isLoading = false;
			},
			downloadKpiModel() {
				this.service
					.readCustomEndpoint(
						"KpiManager/CreateEstimateModels/" + this.idCampaign
					)
					.then((res) => {
						var fileURL = this.$store.getters.base_address + res;

						window.location.assign(fileURL);
					});
			},
			getRolesByCompany() {
				if (this.campaign.id_company) {
					this.service
						.getOptions("UserRole/" + this.campaign.id_company)
						.then((data) => (this.roleList = data));
				}
			},
			getAreasByCompany() {
				if (this.campaign.id_company) {
					this.service
						.getOptions("AT_Areas/" + this.campaign.id_company)
						.then((data) => (this.areaList = data));
				}
			},
			getDepartmentsByCompany() {
				if (this.campaign.id_company) {
					this.service
						.getOptions("AT_Department/" + this.campaign.id_company)
						.then((data) => (this.departmentList = data));
				}
			},
			getClustersByCompany() {
				if (this.campaign.id_company) {
					this.service
						.getOptions("AT_Cluster/" + this.campaign.id_company)
						.then((data) => (this.clusterList = data));
				}
			},
			getUsersByCampaign() {
				this.usersList.splice(1);
				if (this.$route.params.campaignId) {
					this.service
						.readCustomEndpoint(
							"CampaignManager/GetUsersInCampaign",
							this.$route.params.campaignId
						)
						.then((res) => {
							res.forEach((element) => {
								this.usersList.push(element);
							});
						});
				} else {
					console.log("FAIL ");
				}
			},
			getUrlDemo() {
				if (this.userSelected != null) {
					this.service.readCustomEndpoint("Setting").then((res) => {
						if (res) {
							this.url_demo =
								res.url_demo +
								"/debug/" +
								this.$route.params.campaignId +
								"/" +
								this.userSelected +
								"/kpi/" +
								this.campaignName;
						} else {
							console.err("ERROr calling Setting");
						}
					});
				} else {
					this.url_demo = "";
					// this.service.readCustomEndpoint("Setting").then((res) => {
					//   this.url_demo = res.url_demo + "login";
					// });
				}
			},
			resetDataSidebarIframe() {
				this.userSelected = null;
				this.url_demo = "";
			},
			isDebugFunction() {
				this.isDebug = null;
				this.service
					.readCustomEndpoint(
						"CampaignManager/IsDebugEnabledOnCampaign/" +
							this.$route.params.campaignId
					)
					.then((res) => {
						this.isDebug = res;
						console.log("IS DEBUG? ", this.isDebug);
					});
			},
			saveItem() {
				if (this.campaign && this.campaign.id) {
					this.campaign.kpi_mail = this.kpi_mail;
					this.campaignService
						.update(this.campaign)
						.then((response) => {
							if (response.id == this.campaign.id) {
								this.$successToast("Kpis saved");
								this.toNextRoute();
							} else {
								this.$errorToast();
								return false;
							}
						})
						.catch(() => {
							this.$errorToast();
							return false;
						});
				}
			},
			resetData() {
				this.tmpKpi = {
					id: 0,
					id_padre: 0,
					friendly_name: {},
					viewMode: "singola",
					viewTime: "singola",
					rankingView: null,
					goal_type: null,
					numero_azioni: 0,
					ranking_points: 0,
					id_globalKpi: null,
				};
				this.currentKpi = null;
				this.friendlyName_lang = this.default_lang.value;
				this.pluginSelected = 0;
				this.productSelected = 0;
				this.globalKpiOptions.splice(1);
			},
			setTmpKpi(item) {
				let index = this.items.findIndex((x) => x.id === item.id);
				if (index > -1) {
					this.currentKpi = this.items[index];
					this.tmpKpi = JSON.parse(JSON.stringify(this.currentKpi));
					this.pluginSelected = this.items[index].idPlugin;
					this.productSelected = this.items[index].idElementPlugin;
				}

				this.service
					.readCustomEndpoint("GlobalKpi?idCompany=" + this.campaign.id_company)
					.then((res) => {
						let array = res.map((item) => {
							return { value: item.id, text: item.name };
						});
						this.globalKpiOptions.push(...array);
					});
			},
			saveKpi() {
				let item = { ...this.tmpKpi };
				item.idPlugin = this.pluginSelected;
				item.idElementPlugin = this.productSelected;
				const validationObject = this.$validate("kpi", item);
				if (!validationObject.validation) {
					this.$errorToast(validationObject.messages.join(", "));
					return false;
				}

				let params = [{ param: "idCampagna", value: this.idCampaign }];
				if (item.id) {
					this.service
						.update(item)
						.then((response) => {
							if (response.id == item.id) {
								Object.keys(this.currentKpi).forEach((element) => {
									if (Object.prototype.hasOwnProperty.call(item, element)) {
										this.currentKpi[element] = item[element];
									}
								});
								this.$root.$emit("bv::toggle::collapse", "sidebar-kpis");
								this.tree = this.buildTree(this.items);
								this.$successToast();
							} else {
								this.$errorToast();
								return false;
							}
						})
						.catch(() => {
							this.$errorToast();
							return false;
						});
				} else {
					this.service
						.create(item, params)
						.then((response) => {
							if (response.id) {
								item.id = response.id;
								this.items.push(item);
								this.$root.$emit("bv::toggle::collapse", "sidebar-kpis");
								this.tree = this.buildTree(this.items);
								this.$successToast();
							} else {
								this.$errorToast();
								return false;
							}
						})
						.catch(() => {
							this.$errorToast();
							return false;
						});
				}
			},
			deleteCheck(item) {
				if (item.children && item.children.length) {
					this.showAlertModal = true;
				} else {
					this.deleteKpi(item.id);
				}
			},
			deleteKpi(id) {
				this.service
					.delete(id)
					.then((response) => {
						if (response) {
							this.items = this.items.filter(
								(currentItem) => id !== currentItem.id
							);
							this.tree = this.buildTree(this.items);
							this.$successToast();
						} else {
							this.$errorToast();
							return false;
						}
					})
					.catch(() => {
						this.$errorToast();
						return false;
					});
			},
			buildTree() {
				let list = JSON.parse(JSON.stringify(this.items));
				if (!list) return [];
				let map = {},
					node,
					roots = [],
					i;
				for (i = 0; i < list.length; i += 1) {
					map[list[i].id] = i; // initialize the map
					list[i].children = []; // initialize the children
				}

				for (i = 0; i < list.length; i += 1) {
					node = list[i];
					if (node.id_padre && node.id_padre !== "0") {
						if (Object.prototype.hasOwnProperty.call(map, node.id_padre)) {
							list[map[node.id_padre]].children.push(node);
						}
						//else nodo orfano
					} else {
						roots.push(node);
					}
				}
				return roots;
			},
			flattenTree(children, parent) {
				return Array.prototype.concat.apply(
					children.map((x) => {
						x.id_padre = parent || 0;
						return x;
					}),
					children.map((x) => this.flattenTree(x.children || [], x.id))
				);
			},

			hasNextRoute() {
				return CampaignRoutes.hasNextRoute(this, "Kpis");
			},
			toNextRoute() {
				CampaignRoutes.toNextRoute(this, "Kpis", this.idCampaign);
			},
			postNewPosition() {
				let newOrder = [];
				for (let i = 0; i < this.tree.length; i++) {
					newOrder.push({ ID: this.tree[i].id, Ordine: i });
				}
				this.service
					.updateOrder(newOrder)
					.then(() => {
						this.$successToast();
					})
					.catch(() => {
						this.$errorToast();
						return false;
					});
			},
		},
		computed: {
			languages() {
				return this.$parent.companyLanguages;
			},
			default_lang() {
				return this.$parent.default_lang;
			},
		},
		watch: {},
	};
</script>
